<template>
  <v-list-item
    class="clickable"
  >
    <template #prepend>
      <v-img
        width="32"
        height="24"
        :src="language.flag_path"
      />
    </template>
    <p class="ms-5 ps-1"> {{ isSelector ? t('lbl.change_language') : language.label }}</p>
  </v-list-item>
</template>

<script
  setup
  lang="ts"
>
const {t} = useI18n()

const props = defineProps({
  language: {
    type: Object as PropType<Language>,
    required: true
  },
  isSelector: {
    type: Boolean,
    default: false
  }
})
</script>


<template>
  <v-menu>
    <template
      v-slot:activator="{ props }"
    >
      <user-avatar
        v-bind="props"
        class="cursor-pointer"
        :user="user"
      />

      <span
        v-if="!mobile"
        v-bind="props"
        class="cursor-pointer ms-2"
      >
        {{ user?.full_name }}
      </span>
    </template>
    <v-list>
      <notification-enable-button/>
      <language-selector />
      <v-list-item
        to="/logout"
        prepend-icon="mdi-logout"
        :title="t('btn.logout')"
      />
    </v-list>
  </v-menu>
</template>

<script
  setup
  lang="ts"
>
import { useDisplay } from 'vuetify'

const {t} = useI18n()
const {mobile} = useDisplay()
const {data: user} = useAuth()
</script>

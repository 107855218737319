<template>
  <v-menu>
    <template #activator="{props}">
      <language-list-item
        v-if="store.languageCode"
        v-bind="props"
        :language="store.getLanguage"
        is-selector
      />
    </template>
    <v-list>
      <v-list-item v-for="language in availableLanguages">
        <language-list-item
          :language="language"
          @click="onChangeLanguage(language.value)"
        />
      </v-list-item>
    </v-list>
  </v-menu>

</template>

<script
  setup
  lang="ts"
>
import { LANGUAGES } from '~/consts/languages'
import { useLanguageStore } from '~/store/language'

const store = useLanguageStore()
const apiUsers = useApiUsers()
const {getSession} = useAuth()
const config = useRuntimeConfig()

async function onChangeLanguage(language: string) {
  await apiUsers.changeLanguage(language)
  await getSession({
    callbackUrl: config.public.appUrl,
    external: true
  })
  window.location.reload()
}

const availableLanguages = computed(() => {
  return LANGUAGES.filter(language => language.value !== store.languageCode)
})
</script>

<style
  scoped
  lang="scss"
>
.v-list-item--prepend {
  padding: 0 !important;
}
</style>